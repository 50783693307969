import { useEffect } from 'react';
import { landwirtschaftShopId } from '../config';

const Landwirtschaft = () => {
  useEffect(() => {
    localStorage.setItem('club_id', landwirtschaftShopId);
    localStorage.setItem('club_name', 'Er(k)lebe die Welt der Landwirtschaft');

    window.location.assign('/');
  });

  return null;
};
export default Landwirtschaft;
